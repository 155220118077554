<template>
  <!-- 数据看板>招生看板 -->
  <div class="dict">
    <div class="boardTitle">招生看板</div>
    <div class="top">
      <div class="topLeft">
        <div class="echartsItem">
          <div class="echartsTitle">客户转化率
            <el-tooltip placement="top">
              <div slot="content">
                本学期累计（学期划分节点3.15、9.15）<br/>
                意向客户转化率=意向客户/客户总量<br/>
                正式学生转化率=正式学生/意向客户<br/>
                意向客户=有意向+已交定金+缴费中+已缴费；<br/>
                正式客户=已缴费；<br/>
                客户总量是所有状态的总和；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData1).length">
            <div id="echarts1" ></div>
          </div>
          <div class="noEachartData"  v-if="!Object.keys(echartData1).length">暂无数据</div>
        </div>
        <div class="echartsItem">
          <div class="echartsTitle">来源渠道
            <el-tooltip placement="top">
              <div slot="content">
                全部累计<br />
                某渠道占比=该渠道来源客户数量/有渠道来源的客户总量
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData2).length">
            <div id="echarts2" ></div>
          </div>
          <div class="noEachartData"  v-if="!Object.keys(echartData2).length">暂无数据</div>
        </div>
        <!-- <div class="echartsItem">
          <div class="echartsType">
            <p class="echartsTitle">定金转化率
              <el-tooltip placement="top">
                <div slot="content">
                  本学期累计（学期划分节点3.15、9.15）<br/>
                  定金转化率=正式学生/交定金的学生
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
            <el-radio-group v-model="depositProfit" @change="changeDepositProfit">
              <el-radio-button label="1">前10名</el-radio-button>
              <el-radio-button label="2">后10名</el-radio-button>
            </el-radio-group>
          </div>
          <div class="chartsContent long" v-show="Object.keys(echartData3).length">
            <div id="echarts3" ></div>
          </div>
          <div class="noEachartData"  v-if="!Object.keys(echartData3).length">暂无数据</div>
        </div> -->
        <div class="echartsItem">
          <div class="echartsTitle">客户关注点
            <el-tooltip placement="top">
              <div slot="content">
                全部累计<br/>
                某关注点占比=该关注点的关注客户数量/填写关注点的客户总数；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData5).length">
            <div id="echarts5" ></div>
          </div>
          <div class="noEachartData"  v-if="!Object.keys(echartData5).length">暂无数据</div>
        </div>
        <div class="echartsItem">
          <div class="echartsTitle">意向客户储备不足top10
            <el-tooltip placement="top">
              <div slot="content">
                本学期累计（学期划分节点3.15、9.15）<br/>
                意向客户储蓄不足园校是（招生目标*2-意向客户）>0的园校；<br/>
                意向客户=有意向+已交定金+缴费中+已缴费；<br/>
                招生目标=在校生目标-在校生目标-（在校人数-预计流失-预计毕业）；接口返回的在校人数已将预计流失人数、预计毕业人数去掉；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData4).length">
            <div id="echarts4" ></div>
          </div>
          <div class="noEachartData"  v-if="!Object.keys(echartData4).length">暂无数据</div>
        </div>
      </div>
      <div class="topRight">
        <div class="echartsType">
          <p class="echartsTitle">招生完成率
            <el-tooltip placement="top">
              <div slot="content">
                本学期累计（学期划分节点3.15、9.15）<br/>
                招生完成率=本期实际招生人数（定金+全费）/招生目标；<br/>
                招生目标=在校生目标-（在校人数-预计流失-预计毕业）；接口返回的在校人数已将预计流失人数、预计毕业人数去掉；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </p>
          <el-radio-group v-model="profit" @change="changeProfit">
            <el-radio-button label="1">前10名</el-radio-button>
            <el-radio-button label="2">后10名</el-radio-button>
          </el-radio-group>
        </div>
        <div class="tabBox">
          <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
              prop="date"
              label="排名"
              width="50">
              <template  slot-scope="scope">
                <span :class="scope.$index <= 2?'hasBg paiming':'noBg paiming'">{{scope.$index+1}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="校园名称"
              width="100">
            </el-table-column>
            <el-table-column
              prop="rate"
              width="80"
              label="完成率">
              <template  slot-scope="scope">
                {{scope.row.rate}}%
              </template>
            </el-table-column>
            <el-table-column
              prop="rate">
              <template  slot-scope="scope">
                <div class="processBox">
                  <div :class="scope.row.rate>100?'processOn':'processOff'" :style="'width:'+scope.row.rate+'%'"></div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottomLeft">
        <div class="echartsItem" style="width:100%;">
          <div class="echartsTitle">在校生完成率
            <el-tooltip placement="top">
              <div slot="content">
                本学期累计（学期划分节点3.15、9.15）<br />
                在校生完成率=（园校在校生-预计毕业-预计流失+已交全费+已交定金）/在校生目标；<br />
                分公司平均值=【所有园校（园校在校生-预计毕业-预计流失+已交全费+已交定金）/在校生目标】的和/所有园校个数；<br />
                接口返回的在校人数已将预计流失人数、预计毕业人数去掉；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData6).length">
            <div id="echarts6" ></div>
          </div>
          <div class="noEachartData"  v-if="!Object.keys(echartData6).length">暂无数据</div>
        </div>
      </div>
      <div class="bottomRight">
        <div class="echartsType">
          <div class="echartsTitle">招生问题园校
            <el-tooltip placement="top">
              <div slot="content">
                本学期累计（学期划分节点3.15、9.15）<br />
                即是招生完成率后10名园校，又是意向客户储备不足前10的园校；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
          <div class="colorType">
            <span class="circle blue"></span> 意向客户储备不足
            <!-- <span class="colorTypeTitle1">意向客户储备不足</span> -->
            <span class="circle yellow"></span>完成率
            <!-- <span class="colorTypeTitle2">完成率</span> -->
          </div>
        </div>
        <div class="chartsContent long" v-show="Object.keys(echartData7).length">
          <div id="echarts7" ></div>
        </div>
        <div class="noEachartData"  v-if="!Object.keys(echartData7).length">暂无数据</div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%" @open="openDialog"
      @close="handleClose">
      <div id="echarts8"></div>
      <!-- <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>-
  </div>
</template>

<script>
import { extension } from "@/utils/validate";
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      tableLoading: false, // tableloading
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      profit:1,
      depositProfit:1,
      tableData:[],
      dialogVisible:false,
      companyDataList:{},
      echartData1:{
        name:[]
      },
      echartData2:{
        name:[]
      },
      echartData3:{
        name:[]
      },
      echartData4:{
        name:[]
      },
      echartData5:{
        name:[]
      },
      echartData6:{
        name:[]
      },
      echartData7:{
        name:[]
      },
      echartData8:{
        name:[]
      },
      echarts1: null,
      echarts2: null,
      echarts3: null,
      echarts4: null,
      echarts5: null,
      echarts6: null,
      echarts7: null,
      echarts8: null
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("z_supervise:addProject") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    // this.initData()
    this.initEnrollCompleteData(1)
    setTimeout(()=>{
      this.initEcharts1()
      this.initEcharts2()
      this.initEcharts3(1)
      this.initEcharts4()
      this.initEcharts5()
      this.initEcharts6()
      this.initEcharts7()
    },100)
  },
  mounted(){
    window.addEventListener('resize',()=> {
      this.echarts1.resize();
      this.echarts2.resize();
      this.echarts3.resize();
      this.echarts4.resize();
      this.echarts5.resize();
      this.echarts6.resize();
      this.echarts7.resize();
    });
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    changeProfit(val){
      if(val == 1){
        this.initEnrollCompleteData(1)
      }else{
        this.initEnrollCompleteData(2)
      }
    },
    initData(){
      this.$api.getUnderReserve().then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    // 招生完成率
    initEnrollCompleteData(type){
      this.$api.getEnrollComplete({
        type:type
      }).then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
          this.tableData = []
          if(data.name){
            data.name.forEach((e,index)=>{
              this.tableData.push({
                name:e,
                rate:data.rate[index]
              })
            })
          }
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts1(){
      this.$api.getClientRate().then(res=>{
        if(res.data.code == '0'){
          let clientRate = res.data.data
          // clientRate.num = ['200','100','120']
          // clientRate.rate = ['90.0','80.0','70.0']
          this.echartData1 = clientRate
          let data = []
          if(clientRate.num){
            clientRate.num.forEach((e,index)=>{
              data.push({
                name:e,
                value:clientRate.rate[index]
              })
            })
          }
          let echarts1 = this.$echarts.init(document.getElementById("echarts1"));
          var colorList = [
            '#004DA0','#2F5ECB','#73A2F5'
          ];
          let option1 = {
            title: {
              text: 'Funnel',
              show:false,
            },
            tooltip: {
              trigger: 'item',
              backgroundColor: 'rgba(0,0,0,0.7)',
              border:0,
              formatter: function(params){
                if(params.dataIndex == 0){
                  return ''
                }else{
                  let ele = ''
                  ele = '<span style="color:#fff;">转化率<br/><span style="display:inline-block;line-height:10px;width: 10px;height: 10px;border-radius: 50%;background-color: '+colorList[params.dataIndex]+';margin-right:  10px"></span>'+params.value+'%</span>'
                  return ele
                }
              },
            },
            toolbox: {
              feature: {
                dataView: { readOnly: false },
                restore: {},
                saveAsImage: {}
              },
              show:false
            },
            legend: {
              show:false,
              left: 'left',
              data: clientRate.name
            },
            series: [
              {
                name: 'Funnel',
                type: 'funnel',
                left: '15%',
                top: 0,
                bottom: 30,
                width: '80%',
                min: 0,
                max: 100,
                minSize: '20%',
                maxSize: '100%',
                sort: 'descending',
                gap: 2,
                label: {
                  show: true,
                  position: 'left',
                  textStyle:{
                    color:'#6C7293',
                    fontSize:12,
                  },
                  formatter:function(p){
                    return clientRate.name[p.dataIndex]
                  },
                },
                labelLine: {
                  length: 10,
                  show:false,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                },
                itemStyle: {
                  borderColor: '#fff',
                  borderWidth: 3,
                  color: function(params) {
                    return colorList[params.dataIndex]
                  },
                },
                data: data,
              },
              {
                name: 'Funnel',
                type: 'funnel',
                left: '15%',
                top: 0,
                bottom: 30,
                width: '80%',
                min: 0,
                max: 100,
                minSize: '20%',
                maxSize: '100%',
                sort: 'descending',
                gap: 2,
                label: {
                  show: true,
                  position: 'inside',
                  textStyle:{
                    color:'#fff',
                    fontSize:12,
                  },
                  formatter:function(p){
                    return p.name
                  },
                },
                labelLine: {
                  length: 10,
                  show:false,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                },
                itemStyle: {
                  borderColor: '#fff',
                  borderWidth: 3,
                  color: function(params) {
                    return colorList[params.dataIndex]
                  },
                },
                data:data,
                z: 100
              },
            ]
          };
          echarts1.setOption(option1);
          this.echarts1 = echarts1
        }
      })
    },
    initEcharts2(){
      this.$api.getSources().then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
          this.echartData2 = data
          if(data){
            data.forEach(e=>{
              e.value = e.rate
            })
          }
          console.log(data)
          let echarts2 = this.$echarts.init(document.getElementById("echarts2"));
          // 绘制星期-出险数量对比图图表
          let option2  = {
            title:{
              textStyle:{
                fontSize: 14,
                fontWeight: '',
                color: '#333'
              },
            },//标题
            tooltip: {
              show:false
            },//提示框，鼠标悬浮交互时的信息提示
            legend: {
              show: false,
              orient: 'vertical',
              x: 'left',
              data: ['主营业务收入', '营业外收入', '其他收益','投资收益','其他业务收入']
            },//图例属性，以饼状图为例，用来说明饼状图每个扇区，data与下边series中data相匹配
            graphic:{
              type:'text',
              left:'center',
              top:'center',
            },
            series: [
              {
                name:'',//tooltip提示框中显示内容
                type: 'pie',//图形类型，如饼状图，柱状图等
                radius: ['0%', '55%'],//饼图的半径，数组的第一项是内半径，第二项是外半径。支持百分比，本例设置成环形图。具体可以看文档或改变其值试一试
                //roseType:'area',是否显示成南丁格尔图，默认false
                roseType : 'area',
                itemStyle: {
                  normal:{
                    label:{
                      show:true,
                      textStyle:{
                        color:'#6C7293',
                        fontSize:"12",
                        textAlgin:'center'
                      },
                      formatter:function(val){   //让series 中的文字进行换行
                        console.log(val)
                        return val.name+'\n'+val.value+'%';
                      }
                    },//饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等。可以与itemStyle属性同级，具体看文档
                    labelLine:{
                      show:true,
                      lineStyle:{color:'#ececf1'}
                    }//线条颜色
                  },//基本样式
                  emphasis: {
                    textColor:'#6C7293'
                  }//鼠标放在各个区域的样式
                },
                data: data,//数据，数据中其他属性，查阅文档
                color: ['#004DA0','#E7C69C','#2F5ECB','#93252A','#00AB9F'],//各个区域颜色
              },//数组中一个{}元素，一个图，以此可以做出环形图
            ],//系列列表
          };
          echarts2.setOption(option2);
          this.echarts2 = echarts2
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts3(type){
      this.$api.getOrderRate({
        type:type
      }).then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
          this.echartData3 = data
          let echarts3 = this.$echarts.init(document.getElementById("echarts3"));
          let option3 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
			  formatter:function(params){
			  	var relVal =  params[0].value + '%'
			  	return relVal
			  }
            },
            xAxis: {
              type: 'value',
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
              axisLabel: {
                // rotate:30,
                textStyle:{//改变xy轴上文字的颜色
                  color:"#A8A6B9"
                },
                formatter:function(params){
                  return params+'%'
                }
              },
            },
            yAxis: {
              type: 'category',
              data: data.name.reverse(),
              axisLabel:{
                textStyle:{
                  color:"#A8A6B9"
                },
              },
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
            },
            dataZoom: [
              {
                start:0,//默认为0
                end: 50,//默认为100
                type: 'slider',
                maxValueSpan:5,//显示数据的条数(默认显示10个)
                show: true,
                handleSize: 0,//滑动条的 左右2个滑动条的大小
                height: '80%',//组件高度
                left: 600, //左边的距离
                right: 8,//右边的距离
                top: 50,//上边边的距离
                borderColor: "#fff",
                fillerColor: '#C5C3D8',//滑动块的颜色
                backgroundColor: '#fff',//两边未选中的滑动条区域的颜色
                showDataShadow: false,//是否显示数据阴影 默认auto
                showDetail: false,//即拖拽时候是否显示详细数值信息 默认true
                realtime:true, //是否实时更新
                filterMode: 'filter',
                yAxisIndex: [0,1],//控制的y轴
              },
              //滑块的属性
              {
                type: 'inside',
                show: true,
                yAxisIndex: [0,1],
                start: 1,//默认为1
                end: 9,//默认为100
              },
            ],
            grid: {
              left:100,
              right: 50,
              bottom:30
            },
            series: [{
              // data: ['郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园'],
              data: data.rate.reverse(),
              type: 'bar',
              showBackground: true,
              // bottom:10,
              barWidth: 20,//柱图宽度
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [0, 4, 4, 0],
                  color:function(p){
                    if(p.value>=100){
                      return '#E7C69C'
                    }else{
                      return '#004DA0'
                    }
                  }, //柱形背景颜色
                  label: {
                    formatter: "{c}"+'%',
                    show: true,
                    position: "right",
                    textStyle: {
                      fontSize: "14",
                      color: "#9290B1"
                    }
                  },
                },
              },
              backgroundStyle: {
                color: '#F3F3FB'
              },
            }]
          }
          echarts3.setOption(option3);
          this.echarts3 = echarts3
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts4(){
      this.$api.getUnderReserve().then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
          this.echartData4 = data
          let echarts4 = this.$echarts.init(document.getElementById("echarts4"));
          // 绘制星期-出险数量对比图图表
          let option4 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: 'category',
              data: data.name,
              triggerEvent: true,
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
              axisLabel: {
                rotate:30,
                textStyle:{//改变xy轴上文字的颜色
                  color:"#A8A6B9"
                },
                margin: 8,
                formatter:function(params){
                  var val="";
                  if(params.length >4){
                      val = params.substr(0,4)+'...';
                      return val;
                  }else{
                      return params;
                  }
                }
              },
             
            },
            yAxis: {
              type: 'value',
              axisLabel:{
                textStyle:{
                  color:"#A8A6B9"
                }
              }
            },
            grid: {
              top: 10,
              // left:70,
              bottom:50
            },
            series: [{
              data:data.rate,
              type: 'bar',
              barWidth: 20,//柱图宽度
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [4, 4, 0, 0],
                  color: '#004DA0'
                },
              },
            }]
          };
          extension(echarts4);
          echarts4.setOption(option4);
          this.echarts4 = echarts4
        }else{
          this.$message.error(res.data.msg)
        }
      })
      
    },
    initEcharts5(){
      this.$api.getClientFocus().then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          let fouus = res.data.data
          this.echartData5 = fouus
          let indicator = []
          if(fouus.name){
            // fouus.rate.map(Number)
            fouus.rate.map(Number).sort(function(a, b){return a - b})
            fouus.name.forEach((e,index)=>{
              indicator.push({
                name:e+' '+fouus.rate[index]+'%',
                max:fouus.rate[0]
              })
            })
          }
          let echarts5 = this.$echarts.init(document.getElementById("echarts5"));
          let option5 = {
            title: {
              text: 'Basic Radar Chart',
              show:false,
            },
            legend: {
              data: ['Allocated Budget', 'Actual Spending'],
              show:false,
            },
            radar: {
              // shape: 'circle',
              // center: ['60%','60%'],
              radius: '70%',
              indicator: indicator,
              axisName: {
                formatter:'{value}',
                color: '#6C7293'
              },
              splitArea: {
                areaStyle: {
                  color: ['#fff'],
                },
              },
              axisLine: {
                show:false,
                lineStyle: {
                  color: 'rgba(211, 253, 250, 0.8)'
                }
              },
            },
            series: [
              {
                name: 'Budget vs spending',
                type: 'radar',
                data: [
                  {
                    value: fouus.rate.map(Number),
                    name: 'Actual Spending',
                    symbol:'none',
                    itemStyle:{
                      normal:{
                        color:'#003685'
                      }
                    },
                    areaStyle: {
                      color: 'rgba(221,228,253, 0.6)'
                    }
                  }
                ]
              }
            ]
          };
          echarts5.setOption(option5);
          this.echarts5 = echarts5
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts6(){
      this.$api.getInternalComplete().then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
          this.echartData6 = data
          console.log(data)
          let echarts6 = this.$echarts.init(document.getElementById("echarts6"));
          // 绘制星期-出险数量对比图图表
          let option6 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
              formatter:function(params){
                var relVal = params[0].name
                relVal += '<br/>' + params[0].marker + ' ' + params[0].value + '%'
                return relVal
              }
            },
            xAxis: {
              type: 'category',
              data: data.name,
              triggerEvent: true,
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
              axisLabel: {
                rotate:30,
                textStyle:{//改变xy轴上文字的颜色
                  color:"#A8A6B9"
                },
                margin: 8,
                formatter:function(params){
                  var val="";
                  if(params.length >4){
                      val = params.substr(0,4)+'...';
                      return val;
                  }else{
                      return params;
                  }
                },
              },
            },
            yAxis: {
              type: 'value',
              axisLabel:{
                textStyle:{
                  color:"#A8A6B9"
                },
                formatter:function(p){
                  return p+'%'
                }
              },
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
            },
            grid: {
              top: 10,
              left:60,
              bottom:60
            },
            series: [{
              data: data.rate,
              type: 'bar',
              showBackground: false,
              barWidth: 10,//柱图宽度
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [ 4, 4, 0, 0],
                  color:function(p){
                    if(p.dataIndex==(data.rate.length-1)){
                      return '#E7C69C'
                    }else{
                      return '#004DA0'
                    }
                  }, //柱形背景颜色
                },
              },
            }]
          };
          // .getZr()
          let that = this
          echarts6.on('click', (params) => {
            // console.log(params)
            // console.log(data.label[params.dataIndex])
            if(data.label[params.dataIndex]){
              that.companyDataList = data.label[params.dataIndex]
              that.dialogVisible = true
            }else{
              that.companyDataList = null
            }
            // const pointInPixel = [params.offsetX, params.offsetY];
            // if (myChart.containPixel('grid', pointInPixel)) {
            //   let xIndex = myChart.convertFromPixel({seriesIndex: 0}, [params.offsetX, params.offsetY])[0]
            //   this.getAlertList(parseFloat(this.risk_value), this.date_x[xIndex])
            // }
          })
          echarts6.setOption(option6);
          extension(echarts6);
          this.echarts6 = echarts6
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts7(){
      this.$api.getEnrollQuestion().then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
          this.echartData7 = data
          let echarts7 = this.$echarts.init(document.getElementById("echarts7"));
          let option7 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
              formatter:function(val){
                return val[0].marker+'<span style="width:20px;display: inline-block;"></span>'+val[0].name+'<span style="width:20px;display: inline-block;"></span>'+val[0].value+'<br/>'+'<span style=\"display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#E7C69C;\"></span><span style="width:20px;display: inline-block;"></span>'+val[1].name+'<span style="width:20px;display: inline-block;"></span>'+val[1].value+"%"
              }
            },
            legend: {
              data: data.name
            },
            xAxis: [
              {
                type: 'category',
                data: data.name,
                axisTick: {
                  show: false  //不显示坐标轴刻度
                },
                axisLine: {
                  show: false  //不显示坐标轴轴线
                },
                axisLabel: {
                  rotate:30,
                  textStyle:{//改变xy轴上文字的颜色
                    color:"#A8A6B9"
                  }
                },
              }
            ],
            grid: {
              top: 20,
              left: 40,
              right: 40,
              bottom:40
            },
            yAxis: [
              {
                type: 'value',
                name: '',
                axisLabel: {
                  formatter: '{value} '
                },
              },
              {
                type: 'value',
                name: '',
                axisLabel: {
                  formatter: '{value} %'
                }
              }
            ],
            series: [
              {
                name: '',
                type: 'bar',
                data:data.num,
                barWidth: 20,//柱图宽度
                itemStyle: {
                  //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                  normal: {
                    //柱形图圆角，初始化效果
                    barBorderRadius: [ 4, 4, 0, 0],
                    color:'#004DA0'
                  }, //柱形背景颜色
                },
              },
              {
                name: '',
                type: 'line',
                yAxisIndex: 1,
                symbol:'none',
                lineStyle:{ color:'#E7C69C' },
                data: data.rate,
              }
            ]
          };
          echarts7.setOption(option7);
          this.echarts7 = echarts7
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    changeDepositProfit(val){
      if(val == 1){
        // let data = [85, 150, 120, 90, 50, 130, 110]
        this.initEcharts3(1)
      }else{
        let data = [85, 190, 120, 90, 50, 130, 110]
        this.initEcharts3(2)
      }
    },
    initEcharts8(data){
      console.log(data,'echarts8echarts8echarts8')
      this.echartData8 = data
      let echarts8 = this.$echarts.init(document.getElementById("echarts8"));
      // 绘制星期-出险数量对比图图表
      let option8 = {
        xAxis: {
          type: 'category',
          data: data.name,
          axisTick: {
            show: false  //不显示坐标轴刻度
          },
          axisLine: {
            show: false  //不显示坐标轴轴线
          },
          axisLabel: {
            rotate:30,
            textStyle:{//改变xy轴上文字的颜色
              color:"#A8A6B9"
            }
          },
        },
        yAxis: {
          type: 'value',
          axisLabel:{
            textStyle:{
              color:"#A8A6B9"
            },
            formatter:function(p){
              return p+'%'
            }
          },
          axisTick: {
            show: false  //不显示坐标轴刻度
          },
          axisLine: {
            show: false  //不显示坐标轴轴线
          },
        },
        grid: {
          left:60,
          bottom:60
        },
        series: [{
          data: data.rate,
          type: 'bar',
          showBackground: false,
          barWidth: 10,//柱图宽度
          itemStyle: {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [ 4, 4, 0, 0],
              color:function(p){
                if(p.dataIndex==(data.rate.length-1)){
                  return '#E7C69C'
                }else{
                  return '#004DA0'
                }
              }, //柱形背景颜色
            },
          },
        }]
      };
      echarts8.setOption(option8);
      this.echarts8 = echarts8
    },
    handleClose(){
      this.companyDataList = null
    },
    openDialog(){
      this.$nextTick(()=>{
        this.initEcharts8(this.companyDataList)
      })
    },
  },
  beforeDestroy(){
    window.removeEventListener('resize',()=> {
      this.echarts1.resize();
      this.echarts2.resize();
      this.echarts3.resize();
      this.echarts4.resize();
      this.echarts5.resize();
      this.echarts6.resize();
      this.echarts7.resize();
      this.echarts8.resize();
    });
  }
};
</script>

<style lang="scss" scoped>
.dict {
  background-color: #F4F4FC;
  min-width:1200px;
  overflow-x: scroll;
  // height: calc(100% - 40px);
  padding: 34px 30px 30px 30px;
  .boardTitle{
    color: #3D3E4F;
    font-size: 22px;
    font-weight: bold;
  }
  .top{
    display: flex;
    justify-content: space-between;
    .topLeft{
      width: 67%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &:nth-child(2n)  .echartsItem{
        width: 50%;
        background-color: #fff;
      }
      &:nth-child(2n-1)  .echartsItem{
        width: 48.8%;
      }
      .echartsItem{
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
        border-radius: 8px;
        height: 380px;
        margin-top: 20px;
        .echartsTitle{
          padding: 28px 30px;
          font-size: 18px;
          color: #26262E;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow:ellipsis;
        }
      }
    }
    .echartsType{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      padding: 0 30px;
      box-sizing: border-box;
      .echartsTitle{
        padding: 0 !important;
        font-size: 18px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow:ellipsis;
      }
    }
    .topRight{
      width: 30.9%;
      background-color: #fff;
      height: 788px;
      margin-top: 20px;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      box-sizing: border-box;
    }
  }
  .bottom{
    display: flex;
    justify-content: space-between;
    .bottomLeft{
      width: 67%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // height: 380px;
      &:nth-child(2n)  .echartsItem{
        width: 50%;
        background-color: #fff;
      }
      &:nth-child(2n-1)  .echartsItem{
        width: 48.8%;
      }
      .echartsItem{
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
        border-radius: 8px;
        height: 380px;
        margin-top: 30px;
        .echartsTitle{
          padding: 28px 30px;
          font-size: 18px;
          color: #26262E;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow:ellipsis;
        }
      }
    }
    .bottomRight{
      width: 30.9%;
      background-color: #fff;
      height: 380px;
      margin-top: 30px;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      padding: 10px 20px 0;
      // box-sizing: ;
      box-sizing: border-box;
      .echartsType{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;
        box-sizing: border-box;
        .echartsTitle{
          padding: 0 !important;
          font-size: 18px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow:ellipsis;
        }
        .colorType{
          font-size: 14px;
          line-height: 30px;
          .circle{
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            line-height: 30px;
            margin-right: 10px;
          }
          .yellow{
            background: #E7C69C;
            margin-left: 28px;
          }
          .blue{
            background: #004DA0;
          }
        }
      }
    }
  }
}
.chartsContent{
  width: calc(100% - 40px);
  height: calc(100% - 114px);
  padding: 20px;
  &.long{
    height: calc(100% - 88px);
  }
}
#echarts1,#echarts2,#echarts3,#echarts4,#echarts5,#echarts6,#echarts7{
  width: 100%;
  height: 100%;
}
#echarts8{
  width: 100%;
  height: 400px;
}
/deep/.el-radio-button__inner{
  padding: 8px;
}
.paiming{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  font-size: 12px;
}
.hasBg{
  color: #fff;
  background-color: #004DA0;
}
.noBg{
  background: #F1F4F9;
  color: #A8A6B9;
}
.tabBox{
  padding: 20px 30px;
}
.processBox{
  width: 90%;
  height: 12px;
  background-color: #F1F4F9;
  border-radius: 10px;
  .processOff{
    height: 12px;
    background-color: #004DA0;
    border-radius: 10px;
  }
  .processOn{
    height: 12px;
    background-color: #E7C69C;
    border-radius: 10px;
  }
}
/deep/.el-table th>.cell{
  color: #6C7293;
  font-weight: 400;
}
/deep/.el-table .cell{
  color: #6C7293;
}

@media screen and  (max-width: 1366px) {
  // .dict{
  //   min-width:100vw;
  //   overflow-x: scroll;
  // }
}
@media screen and  (max-width: 1560px) {
  .dict .bottom .bottomRight .echartsType .colorType .yellow{
    margin-left: 10px;
  }
  .dict .bottom .bottomRight .echartsType .colorType{
    font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow:ellipsis;
    .colorTypeTitle{
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow:ellipsis;
    }
    .colorTypeTitle1{
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow:ellipsis;
      // width:8em;
    }
    .colorTypeTitle2{
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow:ellipsis;
      // width:3em;
    }
  }
}
.noEachartData{
  height: calc(100% - 74px);
  width: 100%;
  // height: 100%;
  // text-align: center;
  color: #999;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/div{
    display: none;
  }
}
</style>
